// 考评档案管理详情页考评分明细配置文件
export var SearchList = [
  {
    labe: '供应商',
    code: 'companyName',
    type: 'select',
    option: []
  },
  {
    labe: '采购品类',
    code: 'categoryName',
    type: 'select',
    option: [
      { label: '主板、板卡', value: 'PL001' },
      { label: 'OEM整机、防火墙等', value: 'PL002' },
      { label: '硬盘', value: 'PL003' },
      { label: 'SSD', value: 'PL004' },
      { label: '盘阵', value: 'PL005' },
      { label: '光纤HBA卡', value: 'PL006' },
      { label: '光纤模块', value: 'PL007' },
      { label: '外设', value: 'PL008' },
      { label: '自制品', value: 'PL009' },
      { label: '网卡', value: 'PL010' },
      { label: '线缆', value: 'PL011' },
      { label: 'CPU', value: 'PL012' },
      { label: 'GPU、显卡', value: 'PL013' },
      { label: '结构件、附件', value: 'PL014' },
      { label: '内存', value: 'PL015' },
      { label: '背板', value: 'PL016' },
      { label: '中板', value: 'PL017' },
      { label: '交换机', value: 'PL018' },
      { label: 'RAID卡', value: 'PL019' },
      { label: '电源', value: 'PL020' },
      { label: '风扇', value: 'PL021' },
      { label: '散热器', value: 'PL022' },
      { label: '包材', value: 'PL023' },
      { label: '标签', value: 'PL024' },
      { label: '资材类（固资、费用、礼品、印刷品等）', value: 'PL025' },
      { label: '外购软件', value: 'PL026' },
      { label: '服务', value: 'PL027' },
      { label: '商品', value: 'PL028' },
      { label: '第三方采购物料', value: 'PL029' },
      { label: '研发购原材料', value: 'PL030' },
      { label: '仙络原材料', value: 'XL001' }
    ]
  },
  {
    labe: '考评明细',
    code: 'items',
    type: 'select',
    option: [
      { label: '特殊支持细项', value: 'BUYER_SUB_04_01' },
      { label: '保修期', value: 'BUYER_SUB_02_01' },
      { label: '账期', value: 'BUYER_SUB_02_02' },
      { label: '成本', value: 'BUYER_SUB_02_03' },
      { label: '及时率', value: 'BUYER_DETAIL_01' },
      { label: '供货期', value: 'BUYER_DETAIL_02' },
      { label: '交货准确率', value: 'BUYER_DETAIL_03' },
      { label: '返修周期', value: 'BUYER_SUB_03_01' },
      { label: '商务响应', value: 'BUYER_SUB_03_02' },
      { label: '来料合格率', value: 'QUALITY_DETAIL_01' },
      { label: '上线合格率', value: 'QUALITY_DETAIL_02' },
      { label: '批量隔离问题', value: 'QUALITY_DETAIL_03' },
      { label: '上线异常及持续改进', value: 'QUALITY_DETAIL_04' },
      { label: 'QPA/QSA稽查', value: 'QUALITY_DETAIL_05' },
      { label: '错混料问题', value: 'QUALITY_DETAIL_06' },
      { label: '技术能力', value: 'PRODUCT_DETAIL_01' },
      { label: '有效沟通', value: 'PRODUCT_DETAIL_02' },
      { label: '持续改进', value: 'PRODUCT_DETAIL_03' },
      { label: '变更控制1', value: 'PRODUCT_DETAIL_04' },
      { label: '交期特殊支持', value: 'BUYER_DETAIL_04' },
      { label: '延期投诉', value: 'BUYER_DETAIL_05' },
      { label: '市场质量事故', value: 'QUALITY_DETAIL_07' },
      { label: '重大ECN未通知', value: 'QUALITY_DETAIL_08' },
      { label: '环境和职业健康', value: 'QUALITY_DETAIL_09' },
      { label: '变更控制2', value: 'PRODUCT_DETAIL_05' }
    ]
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '未完成', value: '1' },
      { label: '已发布', value: '2' },
      { label: '已完成', value: '3' }
    ]
  },
  {
    labe: '评分方式',
    code: 'strMode',
    type: 'select',
    option: [
      { label: '手工评分', value: '手工评分' },
      { label: '系统自动', value: '系统自动' }
    ]
  },
  { labe: '责任人', code: 'personLiable', type: 'input' }
]
export var SearchData = {
  companyName: '',
  categoryName: '',
  items: '',
  status: '',
  strMode: '',
  personLiable: ''
}
export var tableField = [
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.status === 1 ? '新建' : data.status === 2 ? '已发布' : data.status === 3 ? '已反馈' : data.status === 4 ? '已提交' : data.status === 5 ? '已计算' : ''
    }
  },
  { label: '评分方式', code: 'mode', type: 'input', width: '90%' },
  { label: '供应商编码', code: 'supperCode', type: 'input', width: '100%' },
  { label: '供应商名称', code: 'supperName', type: 'input', width: '190%' },
  { label: '采购品类名称', code: 'categoryName', type: 'input', width: '150%' },
  { label: '考评细项', code: 'items', type: 'input', width: '150%' },
  { label: '评分标准', code: 'standard', type: 'input', width: '180%' },
  { label: '得分', code: 'score', type: 'input', width: '100%' },
  { label: '责任人', code: 'personLiable', type: 'input', width: '100%' },
  { label: '合理分值从', code: 'branchStart', type: 'input', width: '100%' },
  { label: '合理分值至', code: 'branchEnd', type: 'input', width: '100%' },
  { label: '备注', code: 'remake', type: 'input', width: '130%' }
]

export var tableData = []
