<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="evaluation-box">
      <div class="exportData">
        <el-button class="textBtn" @click="preservation" type="text">保存</el-button>
        <el-button v-if="haveButton" class="textBtn" @click="subCalculation" type="text">提交计算</el-button>
        <el-button v-if="haveButton" class="textBtn" @click="sfScore" type="text">下发评分</el-button>
        <el-button v-if="haveButton" class="textBtn" @click="summary" type="text">汇总统计</el-button>
        <el-button v-if="haveButton" class="textBtn" @click="release" type="text">发布</el-button>
        <el-button v-if="haveButton" class="textBtn" @click="toVoidBox" type="text">作废</el-button>
        <el-button v-if="haveButton" class="textBtn" @click="operationRecord" type="text">操作记录</el-button>
        <el-button class="textBtn textBtnRight" @click="back" type="text">返回</el-button>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="基本信息" name="1">
          <div class="elrowflx">
            <el-row :gutter="20">
              <el-col class="clearfix" :span="12">
                <div class="xjtit">档案编号</div>
                <div class="xjcont">{{evaluation.number}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">档案描述</div>
                <div class="xjcont">{{evaluation.message}}</div>
                <!-- <div class="xjcont xjcontMs">
                  <el-input v-model="evaluation.message" placeholder="请输入"></el-input>
                </div> -->
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">状态</div>
                <div class="xjcont">{{evaluation.status=='1'?"新建":evaluation.status=='2'?"计算中":evaluation.status=='3'?"计算完成":evaluation.status=='4'?"计算失败":evaluation.status=='5'?"评分中":evaluation.status=='6'?"汇总完成":evaluation.status=='7'?"已发布":evaluation.status=='8'?"已作废":""}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评季度</div>
                <div class="xjcont">{{evaluation.quarter}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评模板</div>
                <div class="xjcont">{{evaluation.templateName}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">建档时间</div>
                <div class="xjcont">{{evaluation.createDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评日期从</div>
                <div class="xjcont">{{evaluation.startDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评日期至</div>
                <div class="xjcont">{{evaluation.endDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">开发系统计算值</div>
                <div class="xjcont">
                  <el-checkbox :disabled=showDisabled v-model="evaluation.systemValue"></el-checkbox>
                </div>
              </el-col>
              <el-col class="clearfix" :span="24">
                <div class="xjtit">参评说明</div>
                <div class="xjcont">{{evaluation.explain}}</div>
                <!-- <div class="xjcont xjcontCpsm">
                  <el-input type="textarea" v-model="evaluation.explain"></el-input>
                </div> -->
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- 切换列表 -->
      <div class="tabStart">
        <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="1"></table-tab>
      </div>
      <!-- 供应商考评细项 -->
      <div class="tableList toevtlist" v-if="tapNum === 1">
        <div class="table">
          <el-table :data="tableData" ref="tabledeatailData" max-height="350" style="width: 100%">
            <el-table-column prop="status" label="状态" width="100px" :show-overflow-tooltip="true">
              <template #default="scope">
                <div>{{scope.row.status === 1 ? '未完成' : scope.row.status === 2 ? '已发布' : scope.row.status === 3 ? '已完成' : scope.row.status === 4 ? '已提交' : scope.row.status === 5 ? '已计算' : ''}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="strMode" label="评分方式" :show-overflow-tooltip="true">
            <template #default="scope">
                <div>{{scope.row.strMode == '1' ? '系统自动' : scope.row.strMode == '2' ? '手工评分' : scope.row.strMode }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="supperCode" label="供应商编码" width="110px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="supperName" label="供应商名称" width="200px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="categoryName" label="采购品类名称" width="160px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="items" label="考评细项" width="140px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="standard" label="评分标准" width="140px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="score" label="得分" width="100px" :show-overflow-tooltip="true">
              <!-- <template #default="scope">
                <span v-if="scope.row.strMode === '手工评分' && evaluation.status === 0 || evaluation.status === 1 || evaluation.status === 2 || evaluation.status === 3 || evaluation.status === 4 || evaluation.status === 5">
                  <el-input v-model="scope.row.score" placeholder=""></el-input>
                </span>
                <span class="scopeSpan" v-else>{{scope.row.score}}</span>
              </template> -->
              <template #default="scope">
                <span v-if="scope.row.strMode === '手工评分'||scope.row.strMode == '2'">
                  <el-input v-model="scope.row.score" placeholder=""></el-input>
                </span>
                <el-input v-else :disabled=showDisabled v-model="scope.row.score" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="createUserId" label="责任人" width="100px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="branchStart" label="合理分值从" width="110px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="branchEnd" label="合理分值至" width="110px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="remake" label="备注" width="140px" :show-overflow-tooltip="true">
              <template #default="scope">
                  <el-input v-model="scope.row.remake" placeholder=""></el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="tablepage">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size = pageSize
            layout="total,  prev, pager, next, jumper"
            :total = total>
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 查看那个部门已评，那个部门未评 -->
      <div class="tableList toevtlist toevtlistPF" v-if="tapNum === 2">
        <div class="table">
          <el-table :data="tableData" ref="tabledeatailData" max-height="350" style="width: 100%">
            <el-table-column prop="status" label="状态" width="100px" :show-overflow-tooltip="true">
              <template #default="scope">
                <div>{{scope.row.status === 1 ? '新建' : scope.row.status === 2 ? '已发布' : scope.row.status === 3 ? '已反馈' : scope.row.status === 4 ? '已提交' : scope.row.status === 5 ? '已计算' : ''}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="supperCode" label="供应商编码" width="110px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="supperName" label="供应商名称" width="350px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="departmentOne" label="采购部考评" width="" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="departmentTwo" label="产品部考评" width="" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="departmentThree" label="品质部考评" width="" :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
          <div class="tablepage">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size = pageSize
            layout="total,  prev, pager, next, jumper"
            :total = total>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="800px" :dialogShow="dialogShow" :dataBox="dataBox" @handleClose='handleClose' title="操作记录" componentName="OperationBody" :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData } from './FileFillingDetails.js'
import { request } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'FileFillingDetails',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 25) {
      pageSize = 25
    }
    return {
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      parentId: this.$route.query.id,
      dialogShow: false,
      dataBox: {
        id: '',
        supperName: ''
      },
      tableData: [],
      evaluation: {},
      activeNames: ['1'],
      dataStarts: '考评分明细',
      tapNum: 1,
      selectTabData: [],
      haveButton: false,
      childData: { searchList: SearchList, searchData: SearchData },
      navData: [
        {
          label: '考评分明细',
          name: '1'
        },
        {
          label: '考评分汇总',
          name: '2'
        }
      ],
      showDisabled: true
    }
  },
  created () {
    this.myBidData()
    this.supplierList()
    FlowCtlData.getApprPsn('582110313052061696').then((val) => {
      this.haveButton = val.split(';').includes(localStorage.getItem('userName'))
    })
  },

  methods: {
    // 状态切换查询
    onSwitch: function (data) {
      this.dataStarts = data.props.label
      if (data.props.label === '考评分明细') {
        this.tapNum = 1
        this.myBidData()
      }
      if (data.props.label === '考评分汇总') {
        this.tapNum = 2
        this.myBidDataDeits()
      }
    },

    // ID获取考评档案填制-考评分明细详情列表
    myBidData () {
      const id = this.parentId
      const obj = {
        supperName: this.childData.searchData.companyName,
        categoryName: this.childData.searchData.categoryName,
        items: this.childData.searchData.items,
        status: this.childData.searchData.status,
        strMode: this.childData.searchData.strMode,
        personLiable: this.childData.searchData.personLiable
      }
      request('/api/evaluation/evaluation/queryOneCopy?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize + '&' + 'id=' + id, 'POST', obj).then((res) => {
        if (res.code === '200') {
          if (res.data) {
            this.evaluation = res.data.evaluation
            this.tableData = res.data.evaluationIPage.records
            if (this.total !== res.data.evaluationIPage.total) {
              this.total = res.data.evaluationIPage.total
            }
          }
        }
      })
    },

    // ID获取考评档案填制-考评分汇总详情列表
    myBidDataDeits () {
      const id = this.parentId
      const obj = {
        supperName: this.childData.searchData.companyName,
        categoryName: this.childData.searchData.categoryName,
        items: this.childData.searchData.items,
        status: this.childData.searchData.status,
        strMode: this.childData.searchData.strMode,
        personLiable: this.childData.searchData.personLiable
      }
      request('/api/evaluation/evaluation/queryOneCopy?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize + '&' + 'id=' + id, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.supplierEvaluationIPage.records
          if (this.total !== res.data.supplierEvaluationIPage.total) {
            this.total = res.data.supplierEvaluationIPage.total
          }
        }
      })
    },

    // 获取公司数据列表
    supplierList () {
      var newOption = []
      request('/api/supplier/basicData/getForSelect', 'POST').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            newOption.push({
              label: `${~~item.erpCode}-${item.name}`,
              value: `${~~item.erpCode}`
            })
          })
          this.childData.searchList[0].option = newOption
        }
      })
    },

    // 保存
    preservationDbox () {
      var scoreStarts = true
      this.evaluation.list = this.tableData
      const obj = {
        ...this.evaluation
      }
      console.log('obj', obj)
      if (obj.message === '' || obj.message === null) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入档案描述'
        })
        return false
      }
      obj.list.forEach(item => {
        if (item.score === null || item.score === '') {
          scoreStarts = false
        }
      })
      if (!scoreStarts) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '您存在未打分项，请检查完成打分'
        })
        return false
      }

      request('/api/evaluation/evaluation/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 保存弹框
    preservation (data) {
      this.$confirm('确认要保存吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.preservationDbox()
      })
    },

    // 提交计算
    subCalculationDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 3 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 提交计算弹框
    subCalculation (data) {
      this.$confirm('确认要提交计算吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.subCalculationDbox()
      })
    },

    // 下发评分
    sfScoreDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 5 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 下发评分弹框
    sfScore (data) {
      this.$confirm('确认要下发评分吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sfScoreDbox()
      })
    },

    // 汇总统计
    summaryDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 6 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 汇总统计弹框
    summary (data) {
      this.$confirm('确认要汇总统计吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.summaryDbox()
      })
    },

    // 发布
    releaseDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 7 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 发布弹框
    release (data) {
      this.$confirm('确认要发布吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.releaseDbox()
      })
    },

    // 作废
    toVoid () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 8 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 作废弹框
    toVoidBox (data) {
      this.$confirm('确认要作废吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.toVoid()
      })
    },

    // 操作记录弹框
    operationRecord () {
      this.dialogShow = true
      this.dataBox.id = this.parentId
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      if (this.dataStarts === '考评分明细') {
        this.myBidData()
      } else {
        this.myBidDataDeits()
      }
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      if (this.dataStarts === '考评分明细') {
        this.myBidData()
      } else {
        this.myBidDataDeits()
      }
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      if (this.dataStarts === '考评分明细') {
        this.myBidData()
      } else {
        this.myBidDataDeits()
      }
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      if (this.dataStarts === '考评分明细') {
        this.myBidData()
      } else {
        this.myBidDataDeits()
      }
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 5px;
  }
  .textBtn{
    font-size: 16px;
  }
  .textBtnRight{
    float: right;
  }
  .tableList{
    margin-top: 0;
    padding: 0;
    :deep(.el-input__inner){
      height: 34px;
      line-height: 34px;
    }
    :deep(.table .el-table td){
      padding: 10px 0;
    }
    .tablepage{
      text-align: center;
    }
  }
  .toevtlist{
    :deep(.table .el-table td){
      padding: 4px 0;
    }
    .scopeSpan{
      display: block;
      padding: 8px 0;
    }
  }
  .toevtlistPF{
    :deep(.table .el-table td){
      padding: 10px 0;
    }
  }

  .evaluation-box{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 60px;
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      // margin-bottom: 20px
      line-height: 34px;
    }
  }
  .xjtit{
    float: left;
    margin-right: 20px;
    min-width: 98px;
    text-align: right;
    color: #666;
    font-size: 14px;
  }
  .xjcont{
    float: left;
    width: 50%;
    color: #666;
    font-size: 14px;
    line-height: 20px;
    margin-top: 7px;
    margin-bottom: 5px;
    .el-form-item{
      margin-bottom: 0;
    }
  }
  .xjcontMs{
    margin-top: -3px;
    margin-bottom: 0;
    :deep(.el-input__inner){
      line-height: 32px;
      height: 32px;
    }
  }
  .xjcontCpsm{
    width: calc(100% - 222px);
  }
  :deep(.el-collapse-item__header){
    font-size: 16px;
    color: #666;
  }
  :deep(.el-collapse-item__content){
    padding-bottom: 0;
  }
  .el-collapse{
    border-top: 0;
  }

  .tableList .table .el-table__header-wrapper .el-table__header tr, .tableList .table .el-table__header-wrapper .el-table__header th{
    background-color: #ededed;
  }
}
</style>
